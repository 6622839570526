import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import DefaultTemplate from "@src/site/templates/default";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { detectAlpha2Country, detectAlpha2LanguageInPath } from '@src/utils/country';
import { getQueryParameter } from '@src/utils/general';
import { Container, Grid } from '@material-ui/core';
// import { createCookie, isPresent } from '@src/utils/cookie';
import cookie from '@src/utils/cookie';

// components
import Progress from "@src/components/Progress";
import Header from "@src/modules/HeaderModule"
import Footer from "@src/modules/FooterModule";
import Text from '@src/components/Text';
import Icon from "@src/components/Icon";

const {
	snake
} = require(`../snake/core`)

const useStyles = makeStyles(theme => ({
	title: {
		fontSize: "36px",
		width: "100%",
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(4)
	},
	container: {
		width: "auto",
		paddingTop: theme.spacing(6),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingBottom: theme.spacing(6),
		margin: theme.spacing(2),
	},
	element: {
		padding: theme.spacing(1),
		width: "100%"
	},
	icon: {
		marginBottom: theme.spacing(4),
  	},
	redirectText: {
		paddingTop: theme.spacing(2),
		fontSize: "1.1rem !important",
		fontStyle: "normal",
		fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
		fontWeight: "normal",
		lineHeight: "1.6rem !important",
		fontStretch: "normal",
		letterSpacing: "normal"
	}
}))

const Countdown = ({text, link, className}) => {
	const startTime = 10;
	const setMessage = (text, time) => text.replace("<sec>", time);
	const [seconds, setSeconds] = useState(startTime);
	const [countdownText, setCountdownText] = useState(setMessage(text, startTime));

	useEffect(() => {
		if (seconds > 0) {
			setTimeout(() => {
				const leftTime = seconds - 1;
				setSeconds(leftTime);
				setCountdownText(setMessage(text, leftTime))
			}, 1000);
		} else {
			window.location.href = `/${detectAlpha2Country()}`;
		}
	});

	return (
		<div className={className}>
			{countdownText} 
			&nbsp;
			<a href={`/${detectAlpha2Country()}`}>
				{link}
			</a>
		</div>
	);
}

export default function ConfirmCancellation() {
	const [pageModules, setPageModules] = useState({
		header: {},
		footer: {}
	});
	const [gdprRequest, setGdprRequest] = useState({
		loading: true,
		success: false
	});
	const [errorMessage, setErrorMessage] = useState("");
	const classes = useStyles();

	const {
		i18n,
		t
	} = useTranslation()
	const country = detectAlpha2Country();
	const lang = detectAlpha2LanguageInPath();

	const page = {
		content: null,
		tracking: {},
		seo: {},
	}

	useEffect(() => {
		async function fetchData() {
			let site = t('site');
			const headerResult = await snake.getSectionByType('header', site);
			const footerResult = await snake.getSectionByType('footer', site);
			setPageModules({
				header: {...headerResult.data.content, repo: {black: false, dark: true}},
				footer: {
					...footerResult.data.content,
					css: footerResult.data.css
				}
			});

			window.gproxy(false, function () {
				window.gproxy.config.lang(lang);
				const token = getQueryParameter('_t');
				// Apply cancellation request
				if (!token) {
					setGdprRequest({
						loading: false,
						success: false
					});
					setErrorMessage(t('confirmCancellation.responceError'));
				}
				else if (cookie.isPresent(token)) {
					setGdprRequest({
						loading: false,
						success: false
					});
					setErrorMessage(t('confirmCancellation.errorTokenExpired'));
				}
				else {
					window.gproxy.gdpr.delete
						.confirm(decodeURIComponent(token))
						.then((result) => {
							setGdprRequest({
								loading: false,
								success: true
							});
							cookie.createCookie(token);
						})
						.catch((error) => {
							console.error('Error', error);
							setGdprRequest({
								loading: false,
								success: false
							});
							setErrorMessage(t('confirmCancellation.responceError'));
						});
				}
			});
		}

		fetchData();
	}, [])

	return ( 
		<DefaultTemplate page={page}>
			<Helmet>
				<script
				type="text/javascript"
				src={`${process.env.GATSBY_GPROXY_URL}&lang=${lang}`}
				></script>
			</Helmet>
			{gdprRequest.loading ? 
				<Progress fullDevice></Progress>
			: 
				<>
					<Header {...pageModules.header} />
					<Container>
						<Grid 
							container 
							direction="column"
							justify="center"
							alignItems="center"
							className={classes.container}
						>
							<Grid 
								container 
								direction="column"
								justify="center"
								alignItems="center"
								className={classes.element}
							>
								<h1 className = {classes.title} > 
									{t('confirmCancellation.title')} 
								</h1> 
								{gdprRequest.success ? 
									<>
										<Icon className={classes.icon} name="cancel_subscription" />
										<Text variant="bodyM">{t('confirmCancellation.responceSuccess')}</Text>
									</>
								: 
									<>
										<Icon className={classes.icon} name="cancel_subscription" />
										<Text variant="bodyM">{errorMessage}</Text>
									</>
								}
								{!gdprRequest.loading ? <Countdown text={t('confirmCancellation.redirectMessage')} link={t('confirmCancellation.clickHere')} className={classes.redirectText} /> : null}
							</Grid> 
						</Grid>
					</Container> 
					<Footer {...pageModules.footer}/>				
				</>
			}
			
		</DefaultTemplate>
	)
}